import React from "react";
import { Grid } from  "semantic-ui-react";


const TestUpload = () => (
    <>
    <Grid textAlign='center'  verticalAlign='top'>
        <Grid.Column>
            <Grid.Row>
               You did it!
            </Grid.Row>
        </Grid.Column>
    </Grid>
    </>
);

export default TestUpload