import React from 'react'
import LogTable from './LogTable'

function Log() {
    return <LogTable/>
}



export default Log
