import Papa from "papaparse";
import * as XLSX from 'xlsx/xlsx.mjs';
import JsPDF from "jspdf";
import "jspdf-autotable";
import "./fonts/lsansuni-normal"

export function getExportFileBlob({ columns, data, fileType, fileName }) {
  if (fileType === "csv") {
      // CSV example  :: the prepended string ufeff is how Excel knows this is UTF-8
      const headerNames = columns.map((col) => col.exportValue);
      const csvString = Papa.unparse({ fields: headerNames, data});
      return new Blob(["\ufeff", csvString], { type: 'text/csv'});

    } else if (fileType === "xlsx") {
      // XLSX example
  
      const header = columns.map((c) => c.exportValue);
      const compatibleData = data.map((row) => { 
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[index];
        });
        return obj;
      });
  
      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, `${fileName}`);
      XLSX.writeFile(wb, `${fileName}.xlsx`);
  
      // Returning false as downloading of file is already taken care of
      return false;
    }
    //PDF example
    if (fileType === "pdf") {
        
      const headerNames = columns.map((column) => column.exportValue);
      let doc = new JsPDF('landscape')


      doc.autoTable({
        head: [headerNames],
        body: data,
        margin: { top: 20 },
        styles: {
          minCellHeight: 9,
          halign: "left",
          valign: "top",
          fontSize: 10,
          font: 'lsansuni'
        },

        didDrawPage: function (data) {
      
       
          // Footer

          var today = new Date();
          var dd = String(today.getDate()).padStart(2, '0');
          var mm = String(today.getMonth() + 1).padStart(2, '0'); 
          var yyyy = today.getFullYear();

          today = mm + '/' + dd + '/' + yyyy;


       
          doc.setFontSize(8);

       
          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var str = fileName + ", exported " + today + ', pg. ' + doc.internal.getNumberOfPages();
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          doc.text(str, data.settings.margin.left, pageHeight -10);
        }
      });

      doc.save(`${fileName}.pdf`);

    
  
      return false;
    }
  
    // Other formats goes here
    return false;
  }